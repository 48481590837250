<template>
    <NavBar/>
    <ProjectPresentation :project="project"/>
    <div class = pictures>
        <img src="assets/img/cards/randonnee.png" alt="carte d'invitation randonnée" class="randonnee">
        <img src="assets/img/cards/JO1.png" alt="carte d'invitation handball" class="JO">
        <div class = "backgroundRectangle"></div>
    </div>
    <FooterPage/>
</template>


<script>
import NavBar from '../NavBar.vue';
import ProjectPresentation from '../ProjectPresentation.vue'
import FooterPage from '../FooterPage.vue';

export default {
  name: 'CardsProject',
  components: {
    NavBar,
    ProjectPresentation,
    FooterPage,
  },
  data() {
    return{
        project: {
        id: 8,
        color : '#9A7FF9',
        title: 'Cartes d\'invitation',
        categories: ['design graphique', 'print'],
        description: 'Création de cartes d\'invitation à la demande de ma famille pour Noël 2023. Il s\'agit d\'invitations pour les demi-finales et finales des Jeux Olympiques de Paris 2024, ainsi qu\'une invitation pour un séjour de randonnée, incluant un baptème de parapente. J\'ai ainsi réutilisé le pictograme du handball créé pour les JO 2024, et décliné la carte en quatre exemplaires : finales et demi-finales, féminines et masculines. Je me suis servie du site Lexica.art, un générateur d\'images par IA, afin de créer le visuel de la randonnée. J\'ai ensuite utilisé le logiciel Adobe Illustrator pour créer les cartes.',
        class : 'noLink',
        },
    }
    },
}

</script>


<style>

.pictures{
    display : flex;
    flex-direction : column;
    align-items : center;
    position : relative;
}

.randonnee{
    width : 50%;
    height : auto;
    margin-top : 50vh;
    margin-bottom : 50vh;

}

.JO{
    width : 100%;
    height : auto;
}

.backgroundRectangle{
    position : absolute;
    top : calc(8%);
    width : 100%;
    height : 70%;
    background-color : #9A7FF9;
    z-index : -1;
}

@media screen and (max-width : 800px){
    .randonnee{
        margin-top : 30vh;
        margin-bottom : 30vh;
    }
}


@media screen and (max-width : 465px){
    .randonnee{
        margin-top : 10vh;
        margin-bottom : 10vh;
    }
}

</style>
